import React from "react"
import { Box, Heading, Link, Flex } from "@chakra-ui/core"
import { Link as GatsbyLink } from "gatsby"
import Layout from "../components/layout"
import { SectionHeading, Paragraph } from "../components/ui-elements"
import SEO from "../components/seo"
import VirtualAssistant from "./virtual-assistant"

const TermsOfServices = () => {
  return (
    <Layout>
      <SEO title="Terms of services - VERIFiSMART" description="Explore VERIFiSMART's comprehensive Terms & Conditions – covering scope, user responsibilities, services, and more. Understand your rights on our legal-tech platform."/>
      <Box py="16" px="4" w="100%" maxW="containers.xl" mx="auto">
        <Box color="theme-dark" maxW="containers.xl" mx="auto" my="16" w="100%">
          <SectionHeading>
            Terms and Conditions of Use and Services
          </SectionHeading>
          <Box my="8">
            <Heading size="lg" mb="4">
              Table of contents:
            </Heading>
            <Flex flexDir="column">
              <Link href="#scope-and-parties">1. Scope and Parties</Link>
              <Link href="#definitions">2. Definitions</Link>
              <Link href="#amendments-updates">
                3. Amendments/Updates in the Agreement
              </Link>
              <Link href="#use-of-content">
                4. Use of Content and User/Customer Information
              </Link>
              <Link href="#services">
                5. Services
              </Link>
              <Link href="#intellectual-property-rights">
                6. Intellectual Property Rights
              </Link>
              <Link href="#term-and-termination">7. Term and Termination</Link>
              <Link href="#assignment-and-sub-contracting">
                8. Assignment and Sub-Contracting
              </Link>
              <Link href="#liability-and-indemnity">
                9. Liability and Indemnity
              </Link>
              <Link href="#waiver">10. Waiver</Link>
              <Link href="#severance">11. Severance</Link>
              <Link href="#notices">12. Notices</Link>
              <Link href="#applicable-law">
                13. Applicable Law and Jurisdiction
              </Link>
            </Flex>
          </Box>

          <Box id="scope-and-parties" mt="16">
            <Heading size="lg">1. Scope and Parties</Heading>
            <Paragraph>
              1.1. Legal Tech Innovations Ltd trading as ‘VERIFiSMART’ operates and administers the website
              <a href="https://verifismart.com" target="_blank" rel="noreferrer"> 
                <span style={{color: "blue", textDecoration: "underline"}}> www.verifismart.com </span> 
              </a> (the “<b>Website</b>”) 
              and the software as a service platform at 
              <a href="https://app.verifismart.com/" target="_blank" rel="noreferrer">
                <span style={{color: "blue", textDecoration: "underline"}}> https://app.verifismart.com/ </span> 
              </a> 
              (the “<b>Platform</b>”). 
            </Paragraph>
            <Paragraph>
              1.2 ‘VERIFiSMART’ is a state of the art legal-tech platform that allows 
              individuals and companies alike to protect their creations, ideas, 
              and innovations by providing encrypted storage of files and valid digital 
              registrations to be used as proof of the exact moment on which a piece of 
              work has been created (the “<b>Services</b>”). 
            </Paragraph>
            <Paragraph>
              1.2. These Terms and Conditions (the "<b>Agreement</b>") provide general 
              terms and conditions of use for Users of the Website and shall apply to the 
              provision of Services by ‘VERIFiSMART’ to a Customer.
            </Paragraph>
            <Paragraph>
              1.3. Parties to the Agreement are Legal Tech Innovations Ltd, whose registered office
              is at 41 Devonshire Street, Ground Floor, London, United Kingdom, W1G 7AJ 
              ("<b>VERIFiSMART</b>"/”<b>we</b>”/”<b>us</b>”) and the User/Customer (“<b>you</b>”).
            </Paragraph>
            <Paragraph>
              1.4. VERIFiSMART and the User/Customer shall be referred to as the "<b>Parties</b>" 
              collectively and as the "<b>Party</b>" individually.
            </Paragraph>
            <Paragraph>
              1.5. By accessing and using the Website and/or by submitting a Registration Form, 
              you are accepting to be bound by this Agreement.
            </Paragraph>
          </Box>
          <Box id="definitions" mt="16">
            <Heading size="lg">2. Definitions</Heading>
            <Paragraph>In this agreement,</Paragraph>
            <Paragraph>
              2.1. “<b>Content</b>” means the content and information of the Website 
              and the Platform, including, but not limited to, messages, data, 
              information, text, music, sound, photos, graphics, video, maps, icons, 
              software, source codes, design, articles and blog posts, and other material 
              contained on the Website and the Platform (excluding end-to-end encrypted 
              files stored by the User in the Platform); as well as the infrastructure used 
              to provide such content and information on the Website and the Platform.
            </Paragraph>
            <Paragraph>
              2.2. “<b>Deliverables</b>” means any ‘Registration Certificate’ generated by 
              the Platform and delivered as a downloadable document with details on the 
              owner, work, hash, and timestamp of the item registered, as/when requested by 
              the Customer as part of the Services.
            </Paragraph>
            <Paragraph>
              2.3. ”<b>Fees</b>” means the price payable by the Customer to us as part of a 
              Subscription Plan.
            </Paragraph>
            <Paragraph>
              2.4. "<b>Registration Form</b>" means the account registration form completed 
              and submitted by a Customer in the landing page of the Platform to access 
              the Services.
            </Paragraph>
            <Paragraph>
              2.5. “<b>Subscription Plan</b>” means any plan set out on the pricing page of 
              the Website and as selected by the Customer in the ‘Subscriptions’ section of 
              the Platform.
            </Paragraph>
            <Paragraph>
              2.6. "<b>User</b>" means any natural or legal person, who accesses to the 
              Website or the Platform, either as a visitor or as a customer submitting a 
              Registration Form {' '}
              <span style={{textDecoration: "underline"}}> 
                (in case of submission of a Registration Form, the User shall be hereinafter referred to as the
              </span>  "<b>Customer</b>" in relation to the Services).
            </Paragraph>
            <Paragraph>
              2.7. "<b>User/Customer Information</b>" means <br/>
              (i) any information provided by a User to us when using the Website and <br/>
              (ii) any information provided by a Customer to us in relation to the Services.
            </Paragraph>
          </Box>
          <Box id="amendments-updates" mt="16">
            <Heading size="lg">3. Amendments/Updates in the Agreement</Heading>
            <Paragraph>
              3.1. VERIFiSMART shall have right and authorization to change or 
              update any terms and conditions contained in this Agreement and in our 
              {" "}
              <Box
                as={GatsbyLink}
                to="/privacy-policy"
                textDecoration="underline"
              >
                Privacy Policy
              </Box>{" "}
              (which is an integral part of this Agreement), at any time, whether partially or wholly. 
            </Paragraph>
            <Paragraph>  
              3.2. The User/Customer shall not claim, demand or state that they have not 
              been notified about the referred changes and updates by VERIFiSMART or that 
              the referred changes and updates cannot be applicable to the User/Customer 
              if the User/Customer <br/>
              (i) has been directly notified by VERIFiSMART of such changes and updates;<br/> 
              (ii) has used the Website or Platform in any manner; or <br/>
              (iii) submits a Registration Form after changes and updates have been made.<br/> 
            </Paragraph>
            <Paragraph>
              3.3. IT IS THE {' '} 
              <span style={{textDecoration: "underline"}}> 
                USER’S/CUSTOMER’S RESPONSIBILITY 
              </span>{' '} 
              TO CHECK THIS AGREEMENT AND PRIVACY POLICY PERIODICALLY FOR CHANGES/UPDATES AND, {' '} 
              <span style={{textDecoration: "underline"}}> 
                PARTICULARLY, BEFORE SUBMITTING A REGISTRATION FORM 
              </span>{' '} 
              (WHEN AN EXPRESS ACCEPTANCE TO THIS AGREEMENT WILL BE REQUIRED).
            </Paragraph>
          </Box>

          <Box id="use-of-content" mt="16">
            <Heading size="lg">
              4. Use of Content and User/Customer Information
            </Heading>

            <Heading mt="8" size="sm" style={{textDecoration:"underline"}}>
              4.1. Age Restriction
            </Heading>
            <Paragraph>
              In order to make use of the Website – including submitting a Registration Form, 
              Users must not be under the age of 18 or under the legal age in his/her country. 
              Minors are strictly prohibited to use the Website, the Platform, the Content, 
              and the Services. If the User misrepresents his/her age as part of a Registration 
              Form, or any other communication with us, we shall have a right to cancel the 
              access to the Platform and provision of the Service without any prejudice or 
              liability to VERIFiSMART. By accessing/using the Website and its Content or the 
              Services, the User agrees and guarantees that he/she meets these age restriction 
              requirements.
            </Paragraph>
            <Heading mt="8" size="sm" style={{textDecoration:"underline"}}>
              4.2. User/Customer Information and Privacy
            </Heading>
            <Paragraph>
              4.2.1. The User shall agree and guarantee to give complete and accurate 
              information and update the referred information accordingly when the User 
              submits a Registration Form or uses the Content/Services in any manner.
            </Paragraph>
            <Paragraph>
              4.2.2. In the case of a legal entity, the User submitting a Registration 
              Form should be the owner, Director, or authorised representative of such 
              legal entity. For the purposes of this agreement, the Customer would then 
              be such legal entity.
            </Paragraph>
            <Paragraph>
              4.2.3. We will not have access to the Customer’s dashboard on the Platform 
              nor to any files recorded or registered as part of the Services. 
              Such information and files are available and visible only to the Customer.
            </Paragraph>
            <Paragraph>
              4.2.4. A legal entity Customer may authorize additional users to access its 
              dashboard to encrypt, decrypt and register any files on its behalf. It is 
              the Customer’s responsibility to manage such authorised users and remove 
              them from the Platform as/when necessary.
            </Paragraph>
            <Paragraph>
              4.2.5. You are solely responsible for maintaining the confidentiality of 
              your credentials and for all activities that occur under your account. 
              You agree to immediately notify us of any unauthorized use of your account 
              or any other breach of its security. 
            </Paragraph>
            <Paragraph>
              4.2.6. When you submit a Registration Form you will be prompted to the 
              Platform where you will be given an encryption key. 
              WE DO NOT STORE YOUR ENCRYPTION KEY AND ARE THE UNABLE TO ACCESS, CHANGE, 
              RESET, OR REVOKE YOUR ENCRYPTION KEY OR TO GENERATE A NEW ENCRYPTION KEY FOR YOU. {' '}
              <b style={{textDecoration: "underline"}}> 
                YOU MUST ENSURE TO KEEP YOUR ENCRYPTION KEY IN A SECURE PLACE WHERE IT CAN BE 
                ACCESSED ONLY BY YOU WHEN NEEDED. IF YOU FORGET OR LOSE THE ENCRYPTION KEY, IT 
                WILL NOT BE POSSIBLE TO DECRYPT ALREADY EXISTING ENCRYPTED FILES ON YOUR DASHBOARD, 
                THIS MEANS, THAT YOUR DATA WILL BE LOST FOREVER WITHOUT ANY LIABILITY FOR VERIFISMART.
              </b>
            </Paragraph>
            <Paragraph>
              4.2.7. By accepting this Agreement, the User confirms acceptance to our  {' '}
              <Box
                as={GatsbyLink}
                to="/privacy-policy"
                textDecoration="underline"
              >
                Privacy Policy
              </Box>
              , as an integral part of this Agreement.
            </Paragraph>
            <Paragraph>
              4.2.8. The protection of personal data provided by the User as part of the 
              User/Customer Information is of the highest priority for us. As such, we 
              make every effort to ensure compliance with all applicable data protection 
              regulations. For more information, please read our Privacy Policy which 
              forms an integral part of this Agreement. 
            </Paragraph>
            <Paragraph>
              4.2.9. VERIFiSMART collects, processes, and uses your personal data (hereafter 
              “Data”) only if we have obtained your consent for it and/or if a statutory 
              provision allows for the collection, processing, or use of your Data.  As such, 
              we will only collect, process, and use Data necessary for the provision of the 
              Services rendered by VERIFiSMART and for the use and operation of the Website. 
            </Paragraph>
            <Paragraph>
              4.2.10. If the User explicitly accepts our Privacy Policy when submitting a 
              Registration Form, the User agrees to be contacted by us to receive <br/>
              (i) periodic product information, <br/>
              (ii) newsletters and offers, and/or <br/>
              (iii) alerts or any other notification related to the Services. 
              This consent may be revoked by the User at any time by written communication to us.
            </Paragraph>
            <Heading mt="8" size="sm" style={{textDecoration:"underline"}}>
              4.3. Content
            </Heading>
            <Paragraph>
              4.3.1. The User guarantees not to use programmes or functions to generate 
              automated page impressions or content on the Website.  
            </Paragraph>
            <Paragraph>
              4.3.2. The Content is proprietary to us. You agree not to otherwise modify, 
              copy, distribute, transmit, display, perform, reproduce, publish, license, 
              create derivative works from, transfer, or sell or re-sell any information, 
              software, products, or services obtained from or through our Website or 
              Platform.  
            </Paragraph>
            <Paragraph>
              4.3.3. You also agree not to: <br/><br/> 
              (i) use the Website or its Content for any commercial purpose; <br/><br/> 
              (ii) access, monitor or copy the Website, Platform, or any Content by using 
              any robot, spider, scraper or other automated means or any manual process for 
              any purpose without our express written permission; <br/><br/> 
              (iii) take any action that imposes, or may impose, in our discretion, an 
              unreasonable or disproportionately large load on our infrastructure; <br/><br/> 
              (iv) deep-link to any portion of the Website or Platform, for any purpose, 
              without our express written permission; <br/><br/> 
              (v) "frame", "mirror" or otherwise incorporate any part of the Website into any 
              other website without our prior written authorization; or <br/><br/> 
              (vi) attempt to modify, translate, adapt, edit, decompile, disassemble, 
              or reverse engineer any software programs used by VERIFiSMART in connection 
              with the Website, the Platform, or Services.
            </Paragraph>
            <Paragraph>
              4.3.4. VERIFiSMART shall not be liable for any condition and consequence 
              arising out of or relating to the loss or unavailability of the Website, 
              the Platform, the Services and/or any Content as a result of technical 
              reasons.
            </Paragraph>
            <Paragraph>
              4.3.5. <b style={{textDecoration: 'underline'}}> There is no legal advice provided on the Website. </b>
              <span style={{textDecoration: 'underline'}}> The Content and any information provided by the Website are for 
                general information purposes only. Neither the Content nor any information 
                published on the Website relating to the Services constitutes legal advice 
                or gives rise to a solicitor/client relationship. 
                Specialist legal advice should be requested in relation to your specific 
                circumstances.
              </span>
            </Paragraph>
            <Heading mt="8" size="sm" style={{textDecoration:"underline"}}>
              4.4. Third-party content
            </Heading>
            <Paragraph>
              4.4.1. In order to protect the Users from improper advertising or solicitation 
              and to maintain usage integrity on the Website, VERIFiSMART shall have the right 
              to restrict access to Content or Services for any Users, partially or completely.
            </Paragraph>
            <Paragraph>
              4.4.2. There may be third-party links, contents, including advertisements, on the 
              Website. The User shall decide solely whether to access or use or not to use 
              contents of third parties via the Website. Terms and conditions of use of the 
              referred third-party contents shall be determined by their owners. In this regard, 
              the User acknowledges and accepts that VERIFiSMART do not have any power to determine 
              these terms and conditions of use and any dispute or claim in relation to the referred 
              terms and conditions of use shall not be asserted against VERIFiSMART.
            </Paragraph>
            <Paragraph>
              4.4.3. Any advertisements on the Website will be marked as such. 
            </Paragraph>
            <Paragraph>
              4.4.4. VERIFiSMART shall not be liable for the accuracy, quality, completeness, 
              reliability, or credibility of Content provided by third parties on the Website.    
            </Paragraph>
          </Box>
          <Box id="services" mt="16">
            <Heading size="lg">5. Services</Heading>
            <Heading mt="8" size="sm" style={{textDecoration: 'underline'}}>
              5.1. General
            </Heading>
            <Paragraph>
              5.1.1. With effect from the submission of a Registration Form, VERIFiSMART 
              shall, in consideration of the Fees paid by the Customer, provide the Services 
              to the Customer.
            </Paragraph>
            <Paragraph>
              5.1.2. We will make the Services available to you as soon as you submit a 
              Registration Form and select and pay for a Subscription Plan within the Platform.
            </Paragraph>
            <Paragraph>
              5.1.2. We are not responsible for delays outside our control. If access to the 
              Platform/Services is delayed or unavailable due to an event outside our control, 
              then we will contact you as soon as possible to let you know and we will take steps 
              to minimize the effect of the delay. Provided we do this we will not be liable for 
              delays or unavailability of the Platform caused by the event, but if there is a risk 
              of substantial delay you may contact us to cancel your subscription and receive 
              a refund for any credit you have paid for but not received. 
            </Paragraph>
            <Heading mt="8" size="sm" style={{textDecoration: 'underline'}}>
              5.2. Cancellation
            </Heading>
            <Paragraph>
              5.2.1. Customers have a 
              <b> statutory right to cancel </b> 
              the provision of the Services {' '} 
              <span style={{textDecoration: "underline"}}> 
                within 14 days of submitting their payment of the corresponding Fees 
              </span> {' '}
              (depending on the Subscription Plan they choose), without any payment liability.
              <b> 
                This right applies 
              </b> {' '}
              <b style={{textDecoration: 'underline'}}> 
                only if no registration has been requested and therefore, we haven’t provided 
                any Deliverables. 
              </b> {' '}               
              <span style={{textDecoration: "underline"}}> 
                For ‘Pay-As-You-Go’ Customers or ‘Smart Plan’ Customers any used credit 
                cannot be cancelled and there will be no refund of the Customer’s payment for 
                such Service.
              </span>{' '}
                For ‘Start-Up’ or ‘Enterprise’ customers, refunds will be made for cancellations made 
                within the first 14-days of the month. Any cancelation request received after that 
                period will be effective for the next calendar month.
            </Paragraph>
            <Paragraph>
              5.2.2. You can submit your cancellation by clicking on the ‘Cancel Subscription’ 
              section of your dashboard (“Cancellation Request”).
            </Paragraph>
            <Heading mt="8" size="sm" style={{textDecoration: 'underline'}}>
              5.3. Fees, Payments and Refunds
            </Heading>
            <Paragraph>
              5.3.1. The Customer agrees to pay the Fees in advance, upon submission of 
              payment of the Subscription Plan of their choice. Such payment shall be 
              subjected to the terms and conditions of this Agreement.
            </Paragraph>
            <Paragraph>
              5.3.2. All sums payable by the Customer for the Services, are
              <b> inclusive of any value added tax (VAT) </b> 
              that may be payable under the Fees.
            </Paragraph>
            <Paragraph>
              5.3.3. Payments will be executed online via PayPal or by debit or credit card. 
              Payments will be taken in the currency and at the rate stated at the Website and 
              the Subscription section in the Platform at the time of making a payment. 
            </Paragraph>
            <Paragraph>
              5.3.4. VERIFiSMART will not provide any Services until we have received notification 
              of full payment of the Fees in cleared funds.
            </Paragraph>
            <Paragraph>
              5.3.5. As indicated at clause 6.2.1 of this Agreement, 
              <b> no Fees shall be refunded to </b> 
              the Customer 
              <b>once a Registration Certificate has been generated </b>
              , as requested by 
              a Customer in their dashboard. In any other case, the Customer may be refunded 
              any paid for but unused credit at the price set out in the Platform 
              <b> at the day and time of submission of the payment by the Customer </b>
              , in terms of clause 6.2.1.
            </Paragraph>
            <Paragraph>
              5.3.6. All refunds, if applicable, will be processed within 14 business days from 
              your request.
            </Paragraph>
          </Box>
          <Box id="intellectual-property-rights" mt="16">
            <Heading size="lg">6. Intellectual Property Rights</Heading>
            <Paragraph>
              6.1. All Content, any copyrighted material and any other protected proprietary 
              information, names, and trademarks on the Website (“<b>Our Intellectual Property</b>”), 
              as well as any title, rights and interests in Our Intellectual Property, {' '} 
              <span style={{textDecoration: "underline"}}> 
                are exclusively owned by VERIFiSMART. 
              </span> {' '} 
              Nothing in this Agreement shall be construed 
              to provide any right or license to the Users to use Our Intellectual Property for 
              any purpose, without express prior written content of VERIFiSMART. Except as set 
              forth herein, the User shall not in any way copy, modify, publish, transmit, 
              retransmit, display, sell, distribute, or reproduce or use in any manner Our 
              Intellectual Property.
            </Paragraph>
            <Paragraph>
              6.2. Any title, rights, and interests on the Deliverables (including right to 
              use, reproduce, adapt, distribute, transmit and communicate) are hereby 
              assigned to the Customer without any limitation, subject to their acceptance of 
              and compliance with the terms and conditions of this Agreement. 
            </Paragraph>
            <Paragraph>
              6.3. Once a Customer has submitted a Registration Form and paid for a Subscription 
              Plan on the Platform, they will have the right to use our trademark logo on their 
              website, as provided in the ‘Embed’ section of their dashboard. This right is granted 
              by VERIFiSMART solely for the purposes of branding attribution and registration notice 
              (infringement deterrent) on the Customer’s own website. No other use, permission or 
              license is hereby granted or authorised.
            </Paragraph>
          </Box>

          <Box id="term-and-termination" mt="16">
            <Heading size="lg">7. Copyright</Heading>
            <Paragraph>
              7.1. For the sections related to the use of the Website and the Content, and 
              the Users/Customers Information, this Agreement shall be in full force and 
              effect from the date in which the User access the Website until the term one 
              year from the date on which he/she last access the Website.
            </Paragraph>
            <Paragraph>
              7.2. For the sections related to the provision of the Services, this Agreement 
              shall be in full force and effect from the date on which a Registration Form is 
              submitted until the date on which a Cancellation Request is submitted, in 
              accordance with this Agreement.
            </Paragraph>
            <Paragraph>
              7.3. Either Party may terminate the Agreement immediately, by giving written 
              notice to the other Party if the other Party commits any breach (whether or not 
              considered material) to any of the provisions of this Agreement and, if the 
              breach is capable of remedy, fails to remedy it within 15 days after being 
              given written notice giving full particulars of the breach and requiring it to 
              be remedied. A breach shall be considered capable of remedy if the Party in 
              breach can comply with the provision in question in all respects.
            </Paragraph>
            <Paragraph>
              7.4. In the event of termination of this Agreement for any cause, we shall 
              retain any sums already paid to us by the Customer, without prejudice to any 
              other rights we may have whether at law or otherwise if a breached occurred, 
              unless any such sums are to be refunded subject to clause 6.2.1 and 7.6 of 
              this Agreement.
            </Paragraph>
          </Box>
          <Box id="term-and-termination" mt="16">
            <Heading size="lg">8. Term and Termination</Heading>
            <Paragraph>
              8.1. For the sections related to the use of the Website and the
              Content, and the Users/Customers Information, this Agreement shall
              be in full force and effect from the date in which the User access
              the Website until the term one year from the date on which he/she
              last access the Website.
            </Paragraph>
            <Paragraph>
              8.2. For the sections related to the provision of the Services,
              this Agreement shall be in full force and effect from the date on
              which a Registration Form is submitted until the date on which a
              Cancellation Request is submitted, in accordance with this
              Agreement.
            </Paragraph>
            <Paragraph>
              8.3. Either Party may terminate the Agreement immediately, by
              giving written notice to the other Party if the other Party
              commits any breach (whether or not considered material) to any of
              the provisions of this Agreement and, if the breach is capable of
              remedy, fails to remedy it within 15 days after being given
              written notice giving full particulars of the breach and requiring
              it to be remedied. A breach shall be considered capable of remedy
              if the Party in breach can comply with the provision in question
              in all respects.
            </Paragraph>
            <Paragraph>
              8.4. In the event of termination of this Agreement for any cause,
              we shall retain any sums already paid to us by the Customer,
              without prejudice to any other rights we may have whether at law
              or otherwise if a breached occurred, unless any such sums are to
              be refunded subject to clause 5.2.1 and 6.6 of this Agreement.
            </Paragraph>
          </Box>
          <Box id="assignment-and-sub-contracting" mt="16">
            <Heading size="lg">8. Assignment and Sub-Contracting</Heading>
            <Paragraph>
              8.1. The User shall not assign, transfer, or charge any right or warrant hold 
              directly or indirectly related to this Agreement, to any third party, without 
              prior written consent of VERIFiSMART.
            </Paragraph>
            <Paragraph>
              8.2. VERIFiSMART may assign, transfer or charge all or part of its rights, 
              warrants or obligations under this Agreement, at any time, to any third party, 
              without notice to the User. VERIFiSMART may sub-contract the performance of any 
              of its obligations under the Services and this Agreement without the prior 
              written consent of the Customer.  VERIFiSMART shall be responsible for every act 
              or omission of the sub-contractor as if it were an act or omission of VERIFiSMART 
              itself.
            </Paragraph>
          </Box>
          <Box id="liability-and-indemnity" mt="16">
            <Heading size="lg">9. Liability and Indemnity</Heading>
            <Paragraph>
              9.1 Except in respect of death or personal injury caused by VERIFiSMART’s 
              negligence, the we will not by reason of any representation, implied warranty, 
              condition or other term, or any duty at common law or under the express terms 
              contained herein, be liable for any loss of profit or any indirect, special or 
              consequential loss, damage, costs, expenses or other claims (whether caused by 
              Our Partners, agents or otherwise) in connection with the performance of its 
              obligations under these terms and conditions or with the use by the 
              User/Customer of the Website or the Services.
            </Paragraph>
            <Paragraph>
              9.2 The User shall indemnify us against all damages, costs, claims and 
              expenses suffered by us arising from loss or damage to any property 
              (tangible or intangible) caused by the User/Customer from their use of the 
              Website, the Platform, the Content, or the Services.
            </Paragraph>
            <Paragraph>
              9.3 VERIFiSMART shall not be liable to the Customer or be deemed to be in 
              breach of this Agreement by reason of any delay in performing, or any failure 
              to perform any of its obligations under this Agreement, if the delay or failure 
              was due to any cause beyond our reasonable control.
            </Paragraph>
            <Heading mt="8" size="sm" style={{textDecoration: "underline"}}>
              9.4. Waiver of Warranty
            </Heading>
            <Paragraph>
              9.4.1. THE WEBSITE, THE PLATFORM, THE CONTENT AND/OR THE SERVICES ARE 
              PROVIDED "AS IS". THE USER USES THE REFERRED AT HIS/HER OWN RISK AND CONTROL. 
              VERIFiSMART GIVES {' '} 
              <span style={{textDecoration: "underline"}}>
                NO WARRANTIES
              </span>{' '} 
              OF ANY KIND, WHETHER EXPRESS OR IMPLIED,<br/> 
              (I) AGAINST ANY KIND OF DEFECT, SEIZURE, THIRD PARTY OWNERSHIP, INTELLECTUAL 
              PROPERTY OWNERSHIP CLAIMS, AND/OR <br/>
              (II) CONCERNING THE CONTINUITY, ACCURACY, RELIABILITY, QUALITY, UNINTERRUPTIBILITY, 
              OF THE WEBSITE, THE CONTENT AND/OR THE SERVICES.
            </Paragraph>
            <Paragraph>
              9.4.2. THE PROVISIONS OF CLAUSE 11.4.1. ABOVE SHALL NOT BE INTERPRETED AS 
              THAT WE HAVE TAKEN OR WILL TAKE DELIBERATE ACTIONS TO CAUSE ANY KIND OF 
              ISSUES AS REFERRED IN SUCH CLAUSE.
            </Paragraph>
            <Heading mt="8" size="sm" style={{textDecoration: "underline"}}>
              9.5. Limitation of Liability
            </Heading>
            <Paragraph>
              9.5.1. IN NO EVENT WHETHER IN CONTRACT, TORT, OBJECTIVE LIABILITY OR ANY 
              OTHER LEGAL LIABILITY REGULATIONS AND PRINCIPLES SHALL VERIFiSMART BE 
              LIABLE TO THE USER OR ANY THIRD PARTIES RELATED TO THE USER, UNDER ANY 
              CIRCUMSTANCES, WITHOUT LIMITATION IN TIME, FOR ANY CONSEQUENCE, LOST PROFIT, 
              LOST SAVING OR INCIDENTAL, INDIRECT, DIRECT, SPECIAL, PERSONAL, PUNITIVE, 
              MULTIPLE OR CONSEQUENTIAL DAMAGE, LOSS {' '}
              <span style={{textDecoration: "underline"}}>
                ARISING OUT OF THE USER’S USE, ABUSE, INABILITY TO USE, OR MISUSE
              </span>{' '} 
              OF THE WEBSITE, THE CONTENT AND/OR THE SERVICES, EVEN IF VERIFiSMART IS 
              INFORMED ABOUT THE POSSIBILITY OF SUCH DAMAGES AND CONSEQUENCES TO OCCUR.
            </Paragraph>
            <Paragraph>
              9.5.2. IN ANY CASE, VERIFiSMART’S LIABILITY UNDER THIS AGREEMENT IS LIMITED 
              TO THE AMOUNT OF THE FEES PAID BY THE CUSTOMER UNDER THE IMMEDIATELY 
              PRECEDING SUBSCRIPTION PLAN EFFECTIVELY PAID BY THE CUSTOMER.
            </Paragraph>
          </Box>
          <Box id="waiver" mt="16">
            <Heading size="lg">10. Waiver</Heading>
            <Paragraph>
              10.1 No waiver by VERIFiSMART of any breach of this Agreement by the Customer 
              shall be considered as a waiver of any subsequent breach of the same or any 
              other provision.  A waiver of any term, provision or condition of this 
              Agreement shall be effective only if given in writing and signed by the 
              waiving Party and then only in the instance and for the purpose for which the 
              waiver is given.
            </Paragraph>
            <Paragraph>
              10.2 No failure or delay on the part of any Party in exercising any right, 
              power or privilege under these Terms and Conditions shall operate as a waiver 
              of, nor shall any single or partial exercise of any such right, power or 
              privilege preclude any other or further exercise of any other right, power or 
              privilege.
            </Paragraph>
          </Box>
          <Box id="severance" mt="16">
            <Heading size="lg">11. Severance</Heading>
            <Paragraph>
              If any provision contained in this Agreement is found to be illegal or 
              unenforceable for any reason, that provision shall be replaced by a valid one 
              to the maximum extent permissible so as to affect the intent of the Parties 
              and the remainder of this Agreement shall remain in full force and effect. 
              However, if it is determined that the clause found to be illegal or 
              unenforceable could not be reformulated, it is agreed that the clause in 
              question is separable from the remaining provisions of the Agreement and that 
              determination shall not affect the validity and enforceability of these 
              remaining provisions.
            </Paragraph>
          </Box>
          <Box id="notices" mt="16">
            <Heading size="lg">12. Notices</Heading>
            <Paragraph>
              12.1 All notices under these Terms and Conditions shall be in writing and be 
              deemed duly given if signed by, or on behalf of, a duly authorised officer of 
              the Party giving the notice.
            </Paragraph>
            <Paragraph>
              12.2 Notices shall be deemed to have been duly given: <br/> 
              (i) when received, if delivered by post, courier, or other messenger (including 
              registered mail) during normal business hours of the recipient; or <br/> 
              (ii) when sent, if transmitted by e-mail.
            </Paragraph>
            <Paragraph>
              12.3 Service of any document for the purposes of any legal proceedings concerning 
              or arising out of this Agreement shall be effected by either Party by causing such 
              document to be delivered to the other Party at its registered or principal office, 
              or to such other address as may be notified to one Party by the other Party in 
              writing from time to time.
            </Paragraph>
          </Box>
          <Box id="applicable-law" mt="16">
            <Heading size="lg">13. Applicable Law and Jurisdiction</Heading>
            <Paragraph>
              13.1 These Agreement (including any non-contractual matters and obligations 
              arising therefrom or associated therewith) shall be governed by, and 
              construed in accordance with, the laws of England and Wales.
            </Paragraph>
            <Paragraph>
              13.2 Any dispute, controversy, proceedings or claim between the Parties 
              relating to this Agreement (including any non-contractual matters and 
              obligations arising therefrom or associated therewith) shall fall within the 
              exclusive jurisdiction of the courts of England and Wales.
            </Paragraph>
          </Box>
        </Box>
      </Box>
      <VirtualAssistant />
    </Layout>
  )
}

export default TermsOfServices
